import { projectActionType } from "../actionTypes"

// Object containing default project data
const defaultProjectData = {
  organisation_name: "",
  reference: "",
  reference_2: "",
  address: "",
  type_of_hazardous: "Non-hazardous",
  selectedUser: [],
  request_by: "",
  schedule_testing_type: "online",
  samples: [
    {
      sample_reference: "",
      bh_test_other: "BH",
      depth_from: "",
      depth_to: "",
      type: "Core",
      material_id: "",
      date_sampled: "",
      sampled_by: "",
      sampling_method: "",
      sampling_method_detail: "",
      description: "",
      sample_status: "pending",
    },
  ],
}

// Initial state object
const initialState = {
  registerProjectData: defaultProjectData,
  isRegisterSuccess: false,
  projects: [],
  lastUpdateProjects: [],
  isRegisterProjectSuccess: false,
  errorRegisterProject: "",
  testingServices: [],
  project: {},
  isUpdateProjectSuccess: false,
  errorUpdateProject: [],
  isGetListProjectSuccess: false,
  isGetProjectByIdSuccess: false,
  isGetListLastUpdateProjectSuccess: false,
  isGetProjectsSuccess: false,
  listSampleIdIsExist: false,
  listSampleIdNoExist: [],
}

// Reducer function
const project = (state = initialState, action) => {
  switch (action.type) {
    // Case for INIT_REGISTER_PROJECT_DATA action type.
    case projectActionType.INIT_REGISTER_PROJECT_DATA:
      return {
        ...state,
        isRegisterSuccess: false,
        registerProjectData: defaultProjectData,
      }
    // Case for REGISTER_PROJECT_DATA action type.
    case projectActionType.REGISTER_PROJECT_DATA:
      return {
        ...state,
        registerProjectData: action.payload,
      }
    // Case for GET_LIST action type.
    case projectActionType.GET_LIST:
      return {
        ...state,
        projects: action.payload,
      }
    // Case for PROJECT_REGISTER_SUCCESS action type.
    case projectActionType.PROJECT_REGISTER_SUCCESS:
      return {
        ...state,
        isRegisterProjectSuccess: action.payload,
      }
    // Case for ERROR_PROJECT_REGISTER action type.
    case projectActionType.ERROR_PROJECT_REGISTER:
      return {
        ...state,
        errorRegisterProject: action.payload,
      }
    // Case for GET_TESTING_SERVICE action type.
    case projectActionType.GET_TESTING_SERVICE:
      return {
        ...state,
        testingServices: action.payload,
      }
    // Case for GET_PROJECT action type.
    case projectActionType.GET_PROJECT:
      return {
        ...state,
        project: action.payload,
      }
    // Case for UPDATE_PROJECT_SUCCESS action type.
    case projectActionType.UPDATE_PROJECT_SUCCESS:
      return {
        ...state,
        isUpdateProjectSuccess: action.payload,
      }
    // Case for ERROR_UPDATE_PROJECT action type.
    case projectActionType.ERROR_UPDATE_PROJECT:
      return {
        ...state,
        errorUpdateProject: action.payload,
      }
    // Case for GET_PROJECT_BY_ID action type.
    case projectActionType.GET_PROJECT_BY_ID:
      return {
        ...state,
        project: action.payload,
      }
    // Case for GET_LIST_PROJECT_SUCCESS action type.
    case projectActionType.GET_LIST_PROJECT_SUCCESS:
      return {
        ...state,
        isGetListProjectSuccess: action.payload,
      }
    // Case for GET_PROJECT_BY_ID_SUCCESS action type.
    case projectActionType.GET_PROJECT_BY_ID_SUCCESS:
      return {
        ...state,
        isGetProjectByIdSuccess: action.payload,
      }
    // Case for GET_LIST_LAST_UPDATE_PROJECT action type.
    case projectActionType.GET_LIST_LAST_UPDATE_PROJECT:
      return {
        ...state,
        lastUpdateProjects: action.payload,
      }
    // Case for GET_LIST_LAST_UPDATE_PROJECT_SUCCESS action type.
    case projectActionType.GET_LIST_LAST_UPDATE_PROJECT_SUCCESS:
      return {
        ...state,
        isGetListLastUpdateProjectSuccess: action.payload,
      }
    // Case for GET_PROJECTS_SUCCESS action type.
    case projectActionType.GET_PROJECTS_SUCCESS:
      return {
        ...state,
        isGetProjectsSuccess: action.payload,
      }
    case projectActionType.LIST_SAMPLE_ID_IS_EXIST:
      // Set get all projects success status to true/false depending on the payload value
      return {
        ...state,
        listSampleIdIsExist: action.payload,
      };
    case projectActionType.LIST_SAMPLE_ID_NO_EXIST:
      // Set get all projects success status to true/false depending on the payload value
      return {
        ...state,
        listSampleIdNoExist: action.payload,
      };
    // Default fallback case returns the current state.
    default:
      return state
  }
}

// Exporting auth reducer function as default.
export default project
