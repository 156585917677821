import { createBrowserHistory } from 'history'

export const history = createBrowserHistory();

export const objectEquals = (obj1, obj2, keys) => {
  for(let i = 0; i < keys.length; i++) {
    const key = keys[i];
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }

  return true;
}

export const objectIsInList = (obj, list) =>{
  const keys = Object.keys(obj);
  for(let i = 0; i < list.length; i++) {
    const iteratedObj = list[i];
    if (objectEquals(obj, iteratedObj, keys)) {
      return true;
    }
  }

  return false;
}